import React, { useEffect, useState } from "react";

import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import classes from "./OwnerOccupancy.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { IYearlyOccupancy } from "../../../../interfaces";
import { yearlyOccupancyGeneralSelector } from "../../../../../redux/selectors/occupancyRateSelector";
import { isMobileModeSelector } from "../../../../../redux/selectors/appStatusSelector";
import { OCCUPANCY_BY_YEAR_REQUEST } from "../../../../../redux/actions";
import { performanceDataFilterSelector } from "../../../../../redux/selectors/performanceDataSelector";

const tableConfig = [
  {
    label: "Year",
    id: "year",
  },
  {
    label: "January",
    id: "january",
  },
  {
    label: "February",
    id: "february",
  },
  {
    label: "March",
    id: "match",
  },
  {
    label: "April",
    id: "april",
  },
  {
    label: "May",
    id: "may",
  },
  {
    label: "June",
    id: "june",
  },
  {
    label: "July",
    id: "july",
  },
  {
    label: "August",
    id: "august",
  },
  {
    label: "September",
    id: "september",
  },
  {
    label: "October",
    id: "october",
  },
  {
    label: "November",
    id: "november",
  },
  {
    label: "December",
    id: "december",
  },
];

interface ITablePos {
  x: number;
  y: number;
}

export const OwnerOccupancy = () => {
  const generalData = useSelector(yearlyOccupancyGeneralSelector);

  const isMobileView = useSelector(isMobileModeSelector);
  const filters = useSelector(performanceDataFilterSelector);
  const dispatch = useDispatch();

  const [hoveredMatrix, setHoveredMatrix] = useState<undefined | ITablePos>();

  useEffect(() => {
    dispatch({
      type: OCCUPANCY_BY_YEAR_REQUEST,
      payload: filters
        ? {
            owners: filters.ownersToRequest,
            properties: filters.propertiesToRequest,
            beds: filters.bedroomsToRequest,
            year: ["2019", "2020", "2021", "2022", "2023", "2024"],
          }
        : {
            owners: [],
            properties: [],
            beds: [],
            year: ["2019", "2020", "2021", "2022", "2023", "2024"],
          },
    });
  }, [dispatch, filters]);

  const handleHover = (rowIndex?: number, cellIndex?: number) => {
    if (Number.isInteger(rowIndex) && Number.isInteger(cellIndex)) {
      setHoveredMatrix({ x: rowIndex ?? 0, y: cellIndex ?? 0 });
    } else {
      setHoveredMatrix(undefined);
    }
  };

  return (
    <Box className={classes.ownerOccupancyWrapper} id="ownerOccupancy">
      <h4 style={{ fontSize: isMobileView ? "16px" : "24px" }}>
        Owner Occupancy Rate
      </h4>
      <Box
        style={{
          overflowX: "auto",
          marginTop: "8px",
          maxHeight: isMobileView ? undefined : "240px",
          overflowY: isMobileView ? undefined : "scroll",
        }}
      >
        <Table sx={{ mt: 3 }} className={classes.table}>
          <TableHead>
            <TableRow>
              {!isMobileView ? (
                tableConfig.map((column, cellIndex: number) => (
                  <TableCell
                    className={classes.headerCell}
                    key={column.id}
                    sx={{
                      backgroundColor:
                        hoveredMatrix && hoveredMatrix.y === cellIndex - 1
                          ? "#EEFCF8"
                          : undefined,
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))
              ) : (
                <>
                  <TableCell className={classes.headerCell}></TableCell>
                  {generalData &&
                    Object.keys(generalData)
                      .reverse()
                      .map((year: string, cellIndex: number) => (
                        <TableCell
                          className={classes.headerCell}
                          sx={{
                            backgroundColor:
                              hoveredMatrix && hoveredMatrix.y === cellIndex
                                ? "#EEFCF8"
                                : undefined,
                          }}
                          key={year}
                        >
                          {year}
                        </TableCell>
                      ))}
                </>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {generalData &&
              (!isMobileView
                ? Object.keys(generalData)
                    .reverse()
                    .map((year: string, rowIndex: number) => (
                      <TableRow
                        key={year}
                        style={{
                          backgroundColor:
                            hoveredMatrix && hoveredMatrix.x === rowIndex
                              ? "#EEFCF8"
                              : undefined,
                        }}
                      >
                        <TableCell className={classes.yearCell}>
                          {year}
                        </TableCell>
                        {generalData?.[year].map(
                          (monthData: IYearlyOccupancy, cellIndex: number) => (
                            <TableCell
                              sx={{
                                padding: "4px",
                                backgroundColor:
                                  hoveredMatrix && hoveredMatrix.y === cellIndex
                                    ? "#EEFCF8"
                                    : undefined,
                              }}
                              onMouseEnter={() =>
                                handleHover(rowIndex, cellIndex)
                              }
                              onMouseLeave={() => handleHover()}
                              key={monthData.start_date}
                            >
                              <Box
                                className={classes.monthCell}
                                sx={{
                                  backgroundColor:
                                    hoveredMatrix &&
                                    (hoveredMatrix.x === rowIndex ||
                                      hoveredMatrix.y === cellIndex)
                                      ? `rgba(186, 242, 228, ${(
                                        (monthData?.occupancy_rate_portal || 0) +
                                        (monthData?.occupancy_rate_guestyical || 0) +
                                        (monthData?.occupancy_rate_ical || 0)
                                      ).toFixed(2)})`
                                    : `rgba(140, 144, 172, ${(
                                        (monthData?.occupancy_rate_portal || 0) +
                                        (monthData?.occupancy_rate_guestyical || 0) +
                                        (monthData?.occupancy_rate_ical || 0)
                                      ).toFixed(2)})`,
                                  border:
                                    hoveredMatrix &&
                                    hoveredMatrix.x === rowIndex &&
                                    hoveredMatrix.y === cellIndex
                                      ? "1px solid #00CCA0"
                                      : "inherit",
                                  color:
                                    hoveredMatrix &&
                                    hoveredMatrix.x === rowIndex &&
                                    hoveredMatrix.y === cellIndex
                                      ? "#02094F !important"
                                      : "inherit",
                                  fontWeight:
                                    hoveredMatrix &&
                                    hoveredMatrix.x === rowIndex &&
                                    hoveredMatrix.y === cellIndex
                                      ? "500 !important"
                                      : "inherit",
                                }}
                              >
                                {(
                                  ((monthData?.occupancy_rate_portal || 0) +
                                  (monthData?.occupancy_rate_guestyical || 0) +
                                  (monthData?.occupancy_rate_ical || 0)) *
                                  100
                                ).toFixed(2)}
                                %
                              </Box>
                            </TableCell>
                          )
                        )}
                      </TableRow>
                    ))
                : tableConfig.slice(1).map((row, index) => (
                    <TableRow
                      key={row.id}
                      style={{
                        backgroundColor:
                          hoveredMatrix && hoveredMatrix.x === index
                            ? "#EEFCF8"
                            : undefined,
                      }}
                    >
                      <TableCell className={classes.yearCell}>
                        {row.label}
                      </TableCell>
                      {Object.keys(generalData)
                        .reverse()
                        .map((year: string, cellIndex: number) => (
                          <TableCell
                            sx={{
                              padding: "4px",
                              backgroundColor:
                                hoveredMatrix && hoveredMatrix.y === cellIndex
                                  ? "#EEFCF8"
                                  : undefined,
                            }}
                            key={year}
                          >
                            <Box
                              className={classes.monthCell}
                              sx={{
                                backgroundColor:
                                  hoveredMatrix &&
                                  (hoveredMatrix.x === index ||
                                    hoveredMatrix.y === cellIndex)
                                    ? `rgba(186, 242, 228, ${(
                                      (generalData?.[year]?.[index]
                                        ?.occupancy_rate_portal || 0) +
                                      (generalData?.[year]?.[index]
                                        ?.occupancy_rate_guestyical || 0) +
                                      (generalData?.[year]?.[index]
                                        ?.occupancy_rate_ical || 0)
                                    ).toFixed(2)})`
                                  : `rgba(140, 144, 172, ${(
                                      (generalData?.[year]?.[index]
                                        ?.occupancy_rate_portal || 0) +
                                      (generalData?.[year]?.[index]
                                        ?.occupancy_rate_guestyical || 0) +
                                      (generalData?.[year]?.[index]
                                        ?.occupancy_rate_ical || 0)
                                    ).toFixed(2)})`,
                                border:
                                  hoveredMatrix &&
                                  hoveredMatrix.x === index &&
                                  hoveredMatrix.y === cellIndex
                                    ? "1px solid #00CCA0"
                                    : "inherit",
                                color:
                                  hoveredMatrix &&
                                  hoveredMatrix.x === index &&
                                  hoveredMatrix.y === cellIndex
                                    ? "#02094F !important"
                                    : "inherit",
                                fontWeight:
                                  hoveredMatrix &&
                                  hoveredMatrix.x === index &&
                                  hoveredMatrix.y === cellIndex
                                    ? "500 !important"
                                    : "inherit",
                              }}
                              onMouseEnter={() => handleHover(index, cellIndex)}
                              onMouseLeave={() => handleHover()}
                            >
                              {(
                                ((generalData?.[year]?.[index]
                                  ?.occupancy_rate_portal || 0) +
                                  (generalData?.[year]?.[index]
                                    ?.occupancy_rate_guestyical || 0) +
                                  (generalData?.[year]?.[index]
                                    ?.occupancy_rate_ical || 0)) *
                                100
                              ).toFixed(2)}
                              %
                            </Box>
                          </TableCell>
                        ))}
                    </TableRow>
                  )))}
          </TableBody>
        </Table>
      </Box>
    </Box>
  );
};
