import { Box, Divider, Stack } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import classes from "./MonthlyOccupancyVsMarket.module.scss";
import {
  MONTHLY_OCCUPANCY_RATE_REQUEST,
  OCCUPANCY_BY_YEAR_REQUEST,
} from "../../../../../redux/actions";
import { yearlyOccupancySelector } from "../../../../../redux/selectors/occupancyRateSelector";
import {
  getCurrentYearString,
  toDoublePercent,
} from "../../../../../utils/commonUtils";
import {
  longMonthsNames,
  shortMonthsNames,
  yearsList,
} from "../../../../../utils/constants/periodLists";
import { Legend } from "../../../../common/ChartLegend/ChartLegend";
import { PeriodDropdown } from "../../../../common/PeriodDropdown/PeriodDropdown";
import { IYearlyOccupancy } from "../../../../interfaces";
import { isMobileModeSelector } from "../../../../../redux/selectors/appStatusSelector";
import { performanceDataFilterSelector } from "../../../../../redux/selectors/performanceDataSelector";

const CustomTooltip = (props: any) => {
  const { active, payload, isMobileView } = props;

  if (active && payload && payload.length === 5) {
    const guest = payload[0].value;
    const ownerPortal = payload[1].value;
    const ical = payload[2].value;
    const direct = payload[3].value;
    const marketOccupancyRate = payload[4].value;

    return (
      <Box className={`${classes.tooltip}`}>
        <p>{payload[0].payload.month}</p>
        <Divider orientation="horizontal" />
        <Stack gap="4px">
          <p className={classes.tooltipLegends}>
            <div className={`${classes.box} ${classes.green}`} />
            Guest: {`${guest}%`}
          </p>
          <p className={classes.tooltipLegends}>
            <div className={`${classes.box} ${classes.red}`} />
            Owner portal: {`${ownerPortal}%`}
          </p>
          <p className={classes.tooltipLegends}>
            <div className={`${classes.box} ${classes.purple}`} />
            iCal: {`${ical}%`}
          </p>
          <p className={classes.tooltipLegends}>
            <div className={`${classes.box} ${classes.grey}`} />
            Direct: {`${direct}%`}
          </p>
        </Stack>
        <Divider orientation="horizontal" />
        <p className={classes.tooltipTotal}>
          Total: {`${(guest + ownerPortal + ical + direct).toFixed(2)}%`}
        </p>
        <Divider orientation="horizontal" />
        <p className={classes.tooltipLegends}>
          <div className={`${classes.box} ${classes.yellow}`} />
          Market: {`${marketOccupancyRate}%`}
        </p>
      </Box>
    );
  }

  return null;
};

const CustomYAxisTick = (props: any) => {
  const { x, y, payload, isMobileView } = props;
  return (
    <g transform={`translate(${isMobileView ? 0 : 20},${y})`}>
      <text x={0} y={0} textAnchor="start" fontSize={12} fill="#9798A5">
        {payload.value === 0 ? payload.value : `${payload.value}%`}
      </text>
    </g>
  );
};

export const MonthlyOccupancyVsMarket = () => {
  const dispatch = useDispatch();
  const [year, setYear] = useState(getCurrentYearString());

  const filters = useSelector(performanceDataFilterSelector);

  const generalData: IYearlyOccupancy[] | null = useSelector(
    yearlyOccupancySelector(year)
  );

  const isMobileView = useSelector(isMobileModeSelector);

  const arrowWidth = isMobileView ? 6 : 10;
  const tooltipHeight = isMobileView ? 24 : 32;

  const responsiveContainerRef = useRef<any>(null);
  const cartesianGridRef = useRef<any>(null);
  const occupancyRateTooltipRef = useRef<any>(null);

  const [activeTooltipIndex, setActiveTooltipIndex] = useState<
    number | undefined
  >();
  const [cartesianGridWidth, setCartesianGridWidth] = useState(0);
  const [cartesianGridHeight, setCartesianGridHeight] = useState(0);
  const [cartesianGridLeftOffset, setCartesianGridLeftOffset] = useState(0);
  const [cartesianGridBottomOffset, setCartesianGridBottomOffset] = useState(0);
  const [occupancyRateTooltipWidth, setOccupancyRateTooltipWidth] = useState(0);

  const years = yearsList();

  const barWidth = isMobileView ? 12 : 40;

  const occupancyChartLegend = [
    {
      label: "Guest",
      color: "#00B48D",
      legendType: "circle",
    },
    {
      label: "Owner Portal",
      color: "#E76D83",
      legendType: "circle",
    },
    {
      label: "iCal",
      color: "#5D6293",
      legendType: "circle",
    },
    {
      label: "Direct",
      color: "#E0E1EB",
      legendType: "circle",
    },
    {
      label: "Market",
      color: "#EDC255",
      legendType: "line",
    },
  ];

  useEffect(() => {
    dispatch({
      type: OCCUPANCY_BY_YEAR_REQUEST,
      payload: filters
        ? {
            owners: filters.ownersToRequest,
            properties: filters.propertiesToRequest,
            beds: filters.bedroomsToRequest,
            year: [year],
            listed: filters.listedToRequest,
          }
        : {
            owners: [],
            properties: [],
            beds: [],
            year: [year],
            listed: [],
          },
    });
  }, [dispatch, filters, year]);

  const data = generalData
    ? generalData.map((monthData: IYearlyOccupancy, index: number) => {
        return {
          name: shortMonthsNames[index],
          month: `${longMonthsNames[index]}, ${year}`,
          occupancyRate: toDoublePercent(monthData.occupancy_rate_guest),
          marketOccupancyRate: toDoublePercent(monthData.market_occupancy_rate),
          ownerPortal: toDoublePercent(monthData.occupancy_rate_portal),
          ical: toDoublePercent(
            monthData.occupancy_rate_ical + monthData.occupancy_rate_guestyical
          ),
          direct: toDoublePercent(monthData.occupancy_rate_direct),
        };
      })
    : [];

  useEffect(() => {
    const responsiveContainer = responsiveContainerRef?.current?.current;
    const cartesianGrid =
      cartesianGridRef?.current?._reactInternals?.child?.stateNode;
    const occupancyRateTooltip = occupancyRateTooltipRef?.current;

    cartesianGrid &&
      responsiveContainer &&
      setCartesianGridLeftOffset(
        cartesianGrid.getBoundingClientRect().left -
          responsiveContainer.getBoundingClientRect().left
      );
    cartesianGrid &&
      responsiveContainer &&
      setCartesianGridBottomOffset(
        responsiveContainer.getBoundingClientRect().bottom -
          cartesianGrid.getBoundingClientRect().bottom
      );

    cartesianGrid &&
      setCartesianGridWidth(cartesianGrid.getBoundingClientRect().width);
    cartesianGrid &&
      setCartesianGridHeight(cartesianGrid.getBoundingClientRect().height);
    occupancyRateTooltip &&
      setOccupancyRateTooltipWidth(
        occupancyRateTooltip.getBoundingClientRect().width
      );
  }, [activeTooltipIndex]);

  return (
    <div className={classes.occupancyChartContainer} id="vsMarket">
      <div className={classes.occupancyChartHeader}>
        <div className={classes.occupancyLegendContainer}>
          <h4 style={{ fontSize: isMobileView ? "16px" : "24px" }}>
            {isMobileView
              ? "Monthly OR vs Market:"
              : "Monthly Occupancy Rate vs Market"}
          </h4>
          {!isMobileView ? <Divider flexItem orientation="vertical" /> : null}
          {!isMobileView ? <Legend legendData={occupancyChartLegend} /> : null}
        </div>
        <PeriodDropdown
          period={year}
          periodList={years}
          setPeriod={setYear}
          variant="standard"
        />
      </div>
      <ResponsiveContainer
        width="100%"
        height="80%"
        ref={responsiveContainerRef}
      >
        <ComposedChart
          width={600}
          height={300}
          data={data}
          margin={{ top: 0, left: 0, right: 0, bottom: 0 }}
          onMouseMove={({ activeTooltipIndex }) =>
            setActiveTooltipIndex(activeTooltipIndex)
          }
          onMouseLeave={() => setActiveTooltipIndex(undefined)}
        >
          <CartesianGrid
            stroke="#B4B7CF"
            strokeDasharray="2.33 4.66"
            strokeWidth="0.5"
            vertical={false}
            ref={cartesianGridRef}
          />
          <XAxis
            axisLine={false}
            dataKey="name"
            tickLine={false}
            type="category"
            interval={0}
            tick={{ fill: "#9798A5", fontSize: isMobileView ? 10 : 14 }}
          />
          <YAxis
            axisLine={false}
            tickLine={false}
            type="number"
            width={isMobileView ? 24 : 60}
            unit="%"
            tick={<CustomYAxisTick isMobileView={isMobileView} />}
          />
          <Tooltip
            position={{
              x: !activeTooltipIndex
                ? 135
                : (isMobileView && activeTooltipIndex >= data.length - 5) ||
                  (!isMobileView && activeTooltipIndex >= data.length - 2)
                ? cartesianGridLeftOffset +
                  (activeTooltipIndex * cartesianGridWidth) / data.length -
                  145
                : cartesianGridLeftOffset +
                  (activeTooltipIndex * cartesianGridWidth) / data.length +
                  75,
              y: 0,
            }}
            content={
              <CustomTooltip
                isMobileView={isMobileView}
                cartesianGridBottomOffset={cartesianGridBottomOffset}
                tooltipHeight={tooltipHeight}
                barWidth={barWidth}
                arrowWidth={arrowWidth}
                cartesianGridWidth={cartesianGridWidth}
                occupancyRateTooltipWidth={occupancyRateTooltipWidth}
                data={data}
                occupancyRateTooltipRef={occupancyRateTooltipRef}
              />
            }
            cursor={false}
          />
          <Bar
            barSize={barWidth}
            dataKey="occupancyRate"
            stackId="a"
            fill="#00B48D"
            isAnimationActive={false}
            radius={[0, 0, 0, 0]}
          />
          <Bar
            barSize={barWidth}
            dataKey="ownerPortal"
            stackId="a"
            fill="#E76D83"
            isAnimationActive={false}
            radius={[0, 0, 0, 0]}
          />
          <Bar
            barSize={barWidth}
            dataKey="ical"
            stackId="a"
            fill="#5D6293"
            isAnimationActive={false}
            radius={[0, 0, 0, 0]}
          />
          <Bar
            barSize={barWidth}
            dataKey="direct"
            stackId="a"
            fill="#E0E1EB"
            isAnimationActive={false}
            radius={[4, 4, 0, 0]}
          />
          <Line
            dataKey="marketOccupancyRate"
            isAnimationActive={false}
            stroke="#EDC255"
            strokeWidth={3}
            type="monotone"
            filter="drop-shadow(0px 7px 5px rgba(93, 98, 147, 0.43))"
            dot={false}
          />
        </ComposedChart>
      </ResponsiveContainer>
      {isMobileView ? <Legend legendData={occupancyChartLegend} /> : null}
    </div>
  );
};
