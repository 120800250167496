import { call, put, takeLatest } from "redux-saga/effects";
import { UserLoginAPI } from "../../../api/loginUserAPI";
import {
  FORGOT_PASSWORD_ERROR,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
} from "../../actions";

function* workerForgotPassword(action: {
  type: string;
  payload: { property_id: string };
}): Generator<any> {
  try {
    yield call(UserLoginAPI.forgotPassword, action.payload);
    yield put({
      type: FORGOT_PASSWORD_SUCCESS
    });
  } catch (error: any) {
    yield put({
      type: FORGOT_PASSWORD_ERROR,
      payload: { message: error.message }
    });
  }
}

export function* watcherForgotPassword() {
  yield takeLatest(FORGOT_PASSWORD_REQUEST, workerForgotPassword);
}
